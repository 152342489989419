<template>
  <div  v-if="isMobile">
    <MobileHeader v-if="!$route.meta.isNoShowHeader"></MobileHeader>
    <router-view></router-view>
    <MobileFooter v-if="!$route.meta.isNoshowFooter"></MobileFooter>
 </div>
 <div  v-else>
    <WebHeader v-if="!$route.meta.isNoShowHeader"></WebHeader>
    <router-view></router-view>
    <WebFooter v-if="!$route.meta.isNoshowFooter"></WebFooter>
 </div>

 </template>

 <script setup >
 import { onMounted, ref,watch,computed } from "vue";
 import WebHeader from './views/web/common/Header.vue'
 import WebFooter from './views/web/common/Footer.vue'
 import MobileHeader from './views/mobile/common/Header.vue'
 import MobileFooter from './views/mobile/common/Footer.vue'
import {setTheme} from '@/utils/themeColor.js'
import {getAlllange} from "@/api/index"
import { useStore } from 'vuex'
  import { useI18n } from "vue-i18n";  // 引用组件
  const { locale,setLocaleMessage} = useI18n();  // 解构调用函数
localStorage.setItem('langCode',locale.value)

  const store = useStore()
  store.dispatch('fetchCommon')
  const langMessages = computed(()=>{return store.state.languagesMessages})

 // 判断是pc还是移动端
 const isMobile = ref(false);

 const handleResize = () => {
   isMobile.value = window.matchMedia('(max-width: 768px)').matches;
 };


 watch(() => locale.value,
      val => {
        console.log('val',val)
        localStorage.setItem('langCode',locale.value)
        setLocaleMessage(locale.value, langMessages.value[locale.value]);
      }
  )

 //加载语言包
  const getLangCont = (dft) => {
      getAlllange({"group": "pc" }).then(res=>{
        let messages = {}
        const allLanguage = res.data.package
        // todo 获取所有语言包key
        for (let slug in allLanguage){
            for (let i18nName in allLanguage[slug]){
                messages[i18nName] = {}
            }
            break;
        }
        for (let slug in allLanguage){
            for (let i18nName in allLanguage[slug]){
                if(!messages[i18nName]) {
                  messages[i18nName] = {}
                }
                messages[i18nName][slug] = allLanguage[slug][i18nName]
            }
        }
        setLocaleMessage(locale.value, messages[locale.value]);
        store.commit('setLanguagesMessages', messages)
        if (dft) {
          locale.value = dft
          store.commit('setLanguagesCode', dft)
          localStorage.setItem('lang',dft)
        }
      })
    }

 onMounted(() => {
   const url = window.location.href;
   const params = new URLSearchParams(new URL(url).search);
   const urlLang = params.get('lang');
   console.log("url lang",urlLang)
  // 获取语言
  getLangCont(urlLang)
  // 设置主题色
  let themeName = localStorage.getItem("theme");
  setTheme(themeName)
  document.body.style.backgroundColor = 'var(--base-bg-color)';
  document.body.style.color = 'var(--base-font-color)';

   handleResize(); // 初始化时执行一次
   window.addEventListener('resize', handleResize);
   // 移动端适配  如果为移动端则执行函数进行适配
   if (isMobile.value) {
     (function (doc, win) {
       let docEl = doc.documentElement, // 获取html
         resizeEvt = "orientationchange" in window ? "orientationchange" : "resize",
         width = 750, // 设计稿宽，用时只需要修改这一处
         recalc = function () {
           const clientWidth = docEl.clientWidth; // 获取设备尺寸
           if (!clientWidth) return; // 如果没有值，回去
           if (clientWidth > width) {
             // 如果超过设计稿宽度，就给一个固定值
             docEl.style.fontSize = "100px";
             // docEl.style.width = width + "px";
             docEl.style.margin = "0 auto";
           } else {
             docEl.style.fontSize = 100 * (clientWidth / width) + "px";
             docEl.style.width = "";
             docEl.style.margin = "";
           }
         };
       if (!doc.addEventListener) return; // 如果没有这个方法，中断函数
       win.addEventListener(resizeEvt, recalc, false); // 改变大小时调整一下
       doc.addEventListener("DOMContentLoaded", recalc, false); // 加载完成时调整
     })(document, window);
   }

 });

 </script>

 <style lang="less" scoped>
 @import './assets/css/main.css';

 </style>
