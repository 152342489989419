<template>
    <div class="header">
        <div class="moblie-main-center">
            <div class="left">
              <div class="menu" @click="chengeMenu">
                <span v-if="!isShowMenu"><svg t="1703235635184" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4202" width="100%" height="100%"><path d="M192.037 287.953h640.124c17.673 0 32-14.327 32-32s-14.327-32-32-32H192.037c-17.673 0-32 14.327-32 32s14.327 32 32 32zM192.028 543.17h393.608c17.673 0 32-14.327 32-32s-14.327-32-32-32H192.028c-17.673 0-32 14.327-32 32s14.327 32 32 32zM832.161 735.802H192.037c-17.673 0-32 14.327-32 32s14.327 32 32 32h640.124c17.673 0 32-14.327 32-32s-14.327-32-32-32zM705.162 671.594l160-160-160-160z" fill="" p-id="4203"></path></svg></span>
                <span v-else><svg t="1703256534970" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4206" width="100%" height="100%"><path d="M192.037 287.953h640.124c17.673 0 32-14.327 32-32s-14.327-32-32-32H192.037c-17.673 0-32 14.327-32 32s14.327 32 32 32zM832.161 479.169H438.553c-17.673 0-32 14.327-32 32s14.327 32 32 32h393.608c17.673 0 32-14.327 32-32s-14.327-32-32-32zM832.161 735.802H192.037c-17.673 0-32 14.327-32 32s14.327 32 32 32h640.124c17.673 0 32-14.327 32-32s-14.327-32-32-32zM319.028 351.594l-160 160 160 160z" fill="" p-id="4207"></path></svg></span>
              </div>
              <div class="logo" @click="handleClick({path:'/'},-1)"><img :src="appLogin" class="imgW" alt=""></div>
              <ul class="nav-list" :class="[isShowMenu?'open':'']">
                <li v-for="(item,index) in navList" :key="index" :class="{active:activIdx==item.id}" @click="handleClick(item)">
                    {{t(item.langname)}}
                </li>
              </ul>
            </div>
            <div class="right">
              <div class="lang-btn">
                <span @click="handleOpenLange"><img src="@/assets/img/header/yuyan.png" alt="" class="imgW"></span>
              </div>
              <div class="app-btn" @click="download">{{t('downloadApp')}}</div>
              <ul class="lang-list" v-if="isShowLangSelect">
                  <li v-for="(item,index) in langList" :key="index" :class="{active:currentLang==item.slug}" @click="changeLang(item)">
                    {{ item.name }}
                  </li>
                </ul>
            </div>
        </div>
    </div>
  </template>

  <script >
  export default {
    name: 'WebHeader',
  }
  </script>
  <script setup >
  import { ref,onMounted,computed } from "vue";
  import { useRouter } from 'vue-router';
  import { navList } from '@/hooks/mock';
  import { useI18n } from "vue-i18n";  // 引用组件
  import { useStore } from 'vuex'
  const {  locale,t } = useI18n();  // 解构调用函数
  const store = useStore()
  // 获取语言列表
  const  langList = computed(() => store.state.languages)
  const  downloadUrl = computed(() => store.state.download)
  //获取logo
  const  appLogin = computed(() => store.state.appLogin)

    // 切换导航
    const activIdx = ref('');
    const router = useRouter();
    const handleClick = (item, i) => {
      router.push({ path: item.path });
      activIdx.value = item.id;
      if(!i){
        isShowMenu.value = !isShowMenu.value
      }
    };
    // 切换语言
    const currentLang = ref('')
    const isShowLangSelect = ref(false)
    currentLang.value = locale.value
    // 切换语言item
    const changeLang = (item)=>{
      currentLang.value = item.slug
      isShowLangSelect.value = false
      locale.value = item.slug
      localStorage.setItem('lang',item.slug)
    }
    // 打开语言选择
    const handleOpenLange = ()=>{
      isShowLangSelect.value = true
    }

    const download = () =>{
      let browser = {
            versions: function() {
                var u = navigator.userAgent, app = navigator.appVersion;
                return {
                trident: u.indexOf('Trident') > -1, //IE内核
                presto: u.indexOf('Presto') > -1, //opera内核
                webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
                gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
                mobile: !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/), //是否为移动终端
                ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
                android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器
                iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1, //是否为iPhone或者QQHD浏览器
                iPad: u.indexOf('iPad') > -1, //是否iPad
                webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
                };
            }(),
                language: (navigator.browserLanguage || navigator.language).toLowerCase()
        }

        if (browser.versions.ios || browser.versions.iPhone || browser.versions.iPad) {
            //判断是IOS后跳转到相应的链接
            window.location.href = downloadUrl.value.ios
        }else if (browser.versions.android) {
           //判断是android后跳转到相应的链接
            window.open(downloadUrl.value.android)
        }
    }

    // 展开收起菜单
    const isShowMenu = ref(false)
    const chengeMenu = () =>{
      isShowMenu.value = !isShowMenu.value

    }


    onMounted(() => {
      const url = location.href;
      const currPath = url.split('#')[1];
      const idx = navList.findIndex((item) => {
        return item.path === currPath;
      });
      if (idx !== -1) activIdx.value = idx;
    });

  </script>

  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="less" scoped >
  .header{
      .moblie-main-center{
        display:flex;
        justify-content: space-between;
        .left{
          position: relative;

        }
        .menu{
          display:inline-block;
          vertical-align: middle;
          margin-right: 10px;

          span{
            display:block;
            width:50px;
            height:50px;
            .icon{
              display: block;
            }
            .icon path{
              fill: var(--base-font-color);

            }

          }
        }
        .logo{
          display: inline-block;
          width: 158px;
          vertical-align: middle;
          margin-top: -2px;
          cursor: pointer;
          .imgW{
            display: block;
          }
        }
      }
      .nav-list{
        position: absolute;
        left: -200%;
        top: 100%;
        z-index: 200;
        background-color: var(--black-box-bg);
        padding: 20px;
        &.open{
          left: 0;
        }
        li{
          font-size: 22px;
          padding: 10px 20px;
          margin-bottom: 10px;
          cursor: pointer;
          &:hover{
            color: var(--base-keynote-color);
          }
          // &.active{
          //   border-bottom: 2px solid var(--base-keynote-color);
          // }
        }
      }
      .right{
        text-align: right;
          position: relative;
        .lang-btn{
          display: inline-block;
          margin-top: -2px;
          vertical-align: middle;
          span{
            width: 36px;
            display: block;
            cursor: pointer;
            img{
              display: block;
            }
          }
        }
        .lang-list{
          position: absolute;
          right: -10px;
          top: 100%;
          z-index: 200;
          background-color: var(--black-box-bg);
          width: 200px;
          height: auto;
          font-size: 16px;
          text-align: left;
          li{
            padding: 20px;
            cursor: pointer;
            &.active{
              color: var(--base-keynote-color);
            }
          }
        }
        .app-btn{
          display: inline-block;
          font-size: 18px;
          padding: 0 10px;
          height: 42px;
          background: var(--base-keynote-color);
          border-radius: 8px;
          vertical-align: middle;
          text-align: center;
          line-height: 42px;
          color: var(--black-color);
          cursor: pointer;
          margin-left: 20px;
        }
      }
  }
  </style>
