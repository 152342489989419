import { createApp } from 'vue'
import App from './App.vue'
import i18n from '@/utils/i18n'
import store from '@/store/index'
import router from '@/router/index'
import "amfe-flexible/index"
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// import installElementPlus from './plugins/element'

const app = createApp(App)
// installElementPlus(app)
app
    .use(store)
    .use(router)
    .use(i18n)
    .use(ElementPlus)
    .mount('#app')
