// 导入封装好的网络请求类工具
import Network from '../utils/network'

// 公共-国家、语言、下载
export const getComment = param => Network.get('/pc/common', param)
//语言包
export const getAlllange = param => Network.post('/i18n/all', param)
// 热门交易品种
export const getHot = param => Network.get('/pc/quotes/hot', param)
// 平台所有交易所,用户规则页面
export const getQuotesGroup = param => Network.get('/pc/quotes/group', param)
// 文章相关 - 常见问题一级子分类
export const getAricleClass = param => Network.get('/pc/article/help_center', param)
// 文章相关 - 常见问题一级子分类-二级分类
export const getAricleList = param => Network.get('/pc/article/help_center_list', param)
// 文章详情
export const getAricleDetail = param => Network.get('/pc/article/detail', param)
// 隐私政策
export const getAriclePrivacy = param => Network.get('/pc/article/detailBySlug', param)