// 导入axios
import axios from 'axios'
axios.defaults.baseURL ="https://api.mesenta.com/api/v1"
// 请求响应超时时间
axios.defaults.timeout = 10000;
axios.interceptors.request.use(
    config => {
        config.headers['Hp-Language'] = localStorage.getItem('langCode')
        return config
    },
    err => {
        return Promise.reject(err)
    }
)

axios.interceptors.response.use(response => {
    console.log(response)
    const { data } = response
    if ( data.code == "401" ) {
        // 令牌失效，重新登录，把参数加到url中
        // reLogin()
    }
    if ( data == "403") { //未登录
        // ElMessage.error("请重新登陆");
    }
    return response
}, error => {
    const code = error.code;
    if (code == 500) {
        // ElMessage.error("服务器出了点问题");
    }
    return Promise.reject(error)
})

// 封装自己的get/post方法
export default {
    get: function (path = '', data = '') {
        return new Promise(function (resolve, reject) {
            axios
                .get(path+data)
                .then(function (response) {
                    // 按需求来，这里我需要的是response.data，所以返回response.data，一般直接返回response
                    resolve(response.data)
                })
                .catch(function (error) {
                    reject(error)
                })
        })
    },
    post: function (path = '', data = {}) {
        return new Promise(function (resolve, reject) {
            axios
                .post(path, data)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    reject(error)
                })
        })
    },
    download: function (path = '', data = {}) {
        return new Promise(function (resolve, reject) {
            axios
                .get(path, {
                    params: data,
                    responseType: 'blob'
                })
                .then(function (response) {
                    resolve(response)
                })
                .catch(function (error) {
                    reject(error)
                })
        })
    },
}
