<template>
    <div class="footer">
        <div class="moblie-main-center">
          <div class="bottom">
            <p>{{t('contactUs')}} | {{email.value}} </p>
            <p>Copyright © 2023 {{ appTitle }} LIMITED</p>
          </div>
        </div>
    </div>
  </template>

  <script >
  export default {
    name: 'WebFooter',
  }
  </script>
  <script setup >
  import { computed} from "vue";
  import { useStore } from 'vuex'
  import { useI18n } from "vue-i18n";  // 引用组件
  const {  t } = useI18n();  // 解构调用函数
  const store = useStore()
    //获取网站title
    const  appTitle = computed(() => store.state.appTitle)
    //获取客服
    const  email = computed(() => store.state.email)



  </script>

  <style lang="less" scoped >
  .bottom{
      text-align: center;
      font-size: 24px;
      line-height:1.8;
      padding: 64px 0;
    }
  </style>
