import {createStore} from 'vuex'
import {getComment} from "@/api/index"
export default createStore({
    state: {
        isMobile:false,
        languages:[],//语言列表
        contact:[],//客服列表
        download:[],//下载列表
        appLogin:'',//logo
        appTitle:'',//网站title
        email:{},
        languagesMessages:{},//语言集
        
    },
    mutations: {
        setLanguages(state, data) {
            state.languages = data
        },
        setContact(state, data) {
            state.contact = data
        },
        setDownload(state, data) {
            state.download = data
        },
        setAppLogin(state, data) {
            state.appLogin = data
        },
        setAppTitle(state, data) {
            state.appTitle = data
        },
        setEmail(state, data) {
            state.email = data
        },
        setLanguagesMessages(state, data) {
            state.languagesMessages = data
        },

    },
    actions: {
        async fetchCommon({commit}) {
            // return new Promise(async(resolve, reject)=>{
                try {
                    const res = await getComment();
                    if ( res.code !== 200  ) return 
                    console.log(res.data.contact)
                    commit('setLanguages', res.data.languages)
                    commit('setContact', res.data.contact)
                    commit('setEmail', res.data.contact[0])
                    commit('setDownload', res.data.download)
                    commit('setAppLogin', res.data.app_logo)
                    commit('setAppTitle', res.data.app_name)
                    // resolve()
                }catch(err) {
                    console.log(err)
                    // reject()
                }
            // })
        },
        
    },
    modules: {
    }
})
