import { createI18n } from "vue-i18n";

// 以下为语言包单独设置的场景，单独设置时语言包需单独引入
const messages = {
  'zh_CN': {}, // 中文语言包,
  'en': {}, // 英文语言包
  'ja': {}, // 日语言包
  'ko': {}, // 韩语语言包
  'TH': {} // 泰国语言包
}
// 最后 export default，这一步肯定要写的。
// export default new VueI18n({
//   locale: 'zh_CN', // set locale 默认显示英文
//   //locale: localStorage.getItem('lang') || 'zh',    // 语言标识
//   messages: messages, // set locale messages
//   silentTranslationWarn:true
// })

const i18n = createI18n({
  locale: localStorage.getItem('lang') || 'CN', // 设置当前语言类型
  legacy: false, // 如果要支持compositionAPI，此项必须设置为false;
  globalInjection: true, // 全局注册$t方法
  messages,
  silentTranslationWarn:true
});
export default i18n;
