// 导航数据
export const navList = [
    {id:1,name: '入金赠金',langname:'navItem1', path: '/activities2', label: 'activities2'},
    {id:2,name: '合作伙伴',langname:'navItem5', path: '/activities1', label: 'activities1'},
    {id:3,name: '交易规则',langname:'navItem2', path: '/rules', label: 'rules'},
    {id:4,name: '常见问题', langname:'navItem3',path: '/faq', label: 'faq'},
    {id:5,name: '隐私条款', langname:'navItem4',path: '/privacy', label: 'privacy'},
];
// 多语言
export const langList = [
    {name: '简体中文', value: 'zh_CN'},
    {name: '繁体中文', value: 'zh-tw'},
    {name: 'English', value: 'en'},
    {name: 'English(UK)', value: 'uk'},
    {name: 'Polski', value: 'po'},
];
  