import { createRouter,createWebHistory } from 'vue-router' // createWebHashHistory
import { ref, watch } from "vue";
// const ua = window.navigator.userAgent;
// let isMobile = false;
// if (ua.indexOf('iPhone') >= 0) isMobile = true;
// if (ua.indexOf('Android') >= 0) isMobile = true;
// if (ua.indexOf('iPad') >= 0) isMobile = true;

// // 如果是移动端，给 .vue 的命名特殊处理，这里多了一级目录 mobile/
// const path = isMobile ? 'mobile/' : '';
// // 如果是移动端，给 .vue 的命名特殊处理，这里多了一级目录 mobile/
// // const path = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? 'mobile/' : 'pc/'


// const routes = [
//     {
//         path: '/',
//         redirect: '/'
//     },
//     // 这里用 path 来加载不同的 .vue 文件
//     // component: (resolve) => require([`./views/${path}issues.vue`], resolve)
    
// ]

// PC端路由
const routesPc =  [
    {
        path: "/",
        name: "home",
        component: () => import('@/views/web/home/Index.vue')
    },
    {   
        /** 常见问题 */
        path: '/faq',
        name: 'faq',
        component: () => import('@/views/web/faq/Index.vue'),

    },
    {
        path: '/faq/:name',
        name: 'faqlist',
        component: () => import('@/views/web/faq/detailList/Index.vue')
    },
    {
        path: '/faq/:name/:detailName',
        name: 'faqdetail',
        component: () => import('@/views/web/faq/detail/Index.vue')
    },
    {
        /** 交易规则 */
        path: '/rules',
        name: 'rules',
        component: () => import('@/views/web/rules/Index.vue'),
    },
    {   
        /** 活动1 入金赠金 */
        path: '/activities2',
        name: 'activities2',
        component: () => import('@/views/web/activities/ActivePage2.vue'),
    },
    {   
        /** 活动2 合作伙伴 */
        path: '/activities1',
        name: 'activities1',
        component: () => import('@/views/web/activities/ActivePage1.vue'),
    },
    {   
        /** 隐私政策 */
        path: '/privacy',
        name: 'privacy',
        component: () => import('@/views/web/privacy/Index.vue'),
    },
    {
        path: '/notice',
        name: 'noticedetail',
        component: () => import('@/views/web/notice/Index.vue')
    },
]
 
// 移动端路由表
const routesMB = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/mobile/home/Index.vue')
    },
    {   
        /** 常见问题 */
        path: '/faq',
        name: 'faq',
        component: () => import('@/views/mobile/faq/Index.vue'),

    },
    {
        path: '/faq/:name',
        name: 'faqlist',
        component: () => import('@/views/mobile/faq/detailList/Index.vue')
    },
    {
        path: '/faq/:name/:detailName',
        name: 'faqdetail',
        component: () => import('@/views/mobile/faq/detail/Index.vue')
    },
    {
        /** 交易规则 */
        path: '/rules',
        name: 'rules',
        component: () => import('@/views/mobile/rules/Index.vue'),
    },
    {   
        /** 活动1 入金赠金 */
        path: '/activities2',
        name: 'activities2',
        component: () => import('@/views/mobile/activities/ActivePage2.vue'),
    },
    {   
        /** 活动2 合作伙伴 */
        path: '/activities1',
        name: 'activities1',
        component: () => import('@/views/mobile/activities/ActivePage1.vue'),
    },
    {   
        /** 隐私政策 */
        path: '/privacy',
        name: 'privacy',
        component: () => import('@/views/mobile/privacy/Index.vue'),
    },
    {
        path: '/notice',
        name: 'noticedetail',
        component: () => import('@/views/mobile/notice/Index.vue')
    },
]

const routesQr = {
    path: '/qrCode',
    name: 'qrCode',
    meta: {
        isNoShowHeader: true,
        isNoshowFooter: true
    },
    component: () => import('@/views/qrCode.vue')
}
 
// 声明变量用来接routers
var routes = [];
const screenWidth = ref()
// 获取页面宽度
screenWidth.value = document.body.clientWidth
if (screenWidth.value <= 758) {
    console.log('is Mobile');
    routes = routesMB
} else {
    routes = routesPc
}
routes.push(routesQr)

window.onresize = () => {
    screenWidth.value = document.body.clientWidth;
};
// 监听页面宽度
watch(screenWidth, (newVal) => {
    let timer = setTimeout(() => {
        if (newVal <= 758) {
            // 小于758就是移动端 就把移动端路由表赋给routes
            console.log('is Mobile');
            routes = routesMB
            window.location.reload()
        } else {
            // 大于就是pc端，把pc端路由表赋给routes
            routes = routesPc
            window.location.reload()
        }
        // 重绘页面
    }, 500)
    return () => {
        // 清除定时器
        clearTimeout(timer)
    }
}, {
    // 深度清除
    deep: true,
})


const router = createRouter({
    // base: '/',
    history: createWebHistory(),
    routes
})



export default router
